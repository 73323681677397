import React from "react";
import heroImg from "./hero.png";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";


export const blogSolved: ArticleMetadata = {
    id: "blogSolved",
    shortTitle: "Solving the Royal Game of Ur",
    fullTitle: "Solving the Royal Game of Ur",
    description: "Here's our story of how we started trying to make bots that could compete with people, and ended up solving the Royal Game of Ur completely!",
    htmlDescription: <>
        Here&apos;s our story of how we started trying to make
        bots that could compete with people, and ended up
        solving the Royal Game of Ur completely!
    </>,
    path: "/blog/solved",
    date: "10th March, 2025",
    datetime: "20250310T090000Z",
    authors: [{
        name: "Padraig Lamont",
        url: "https://padraiglamont.com",
    }],
    image: {
        src: heroImg,
        alt: "Our new flawless Panda bot quietly considering a position.",
    },
    dir: [
        { label: "Home", href: "/" },
        { label: "Blog", href: "/blog" },
    ],
};
